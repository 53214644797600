// VARIABLES DEVARIA
/* variables.module.scss */
:root {
  --primary-color-1: #0b8ed8;
  --primary-color-2: #BFDDF4;
  --primary-color-3: #0B8ED8;
  --background-color-dark-primary: #343434;
  --background-color-light-primary:#ffffff;
  --detail-color-light-1: #81BEEA;
  --detail-color-light-2: #0B8ED8;
  --detail-color-light-3: #0B8ED8;
  --background-login-detail: #343434;
}
$primary-color-1:#0b8ed8;
$primary-color-2:#0b8ed8;
$primary-color-3:#0B8ED8;
$secondary-color-1 :#0b8ed8;
$detail-color-light-1:#f2f2f2;
$detail-color-light-2:#0b8ed8;
$detail-color-light-3:#0B8ED8;
$detail-color-light-4:#0B8ED8;
$detail-color-light-5: #BFDDF4;
$detail-color-light-6: #FFD394;
$text-color-dark: #F0F5FF;
$text-color-light: #343434;
$primary-color-mais1code:#FFA252;
$secondary-color-mais1code:#7050F3;
$footer-color-primary: #0B8ED8;
$footer-color-secondary: #81BEEA;
$footer-color-tertiary: #BFDDF4;
$footer-color-quartiary: #F2F2F2;
$footer-color-quintiary: #0B8ED8;
$background-modal-tela-assistir: #232021;
$background-tooltip: #232021;
$dot-slider-secondary: #A7FAE9;
$backgound-status-trasactions-primary:#d8b1b13b;
$backgound-status-trasactions-secondary:#525252;
$backgound-status-trasactions-tertiary:#f2c84c12;
$status-trasactions-primary:#d42d2d;
$status-trasactions-secondary:#81beea;
$background-color-dark-primary:#343434;
$background-color-dark-secondary:#373636;
$background-color-dark-tertiary:#525252;
$background-color-dark-quartiary: #232021;
$background-modal-suggestions: #000000d6;
$background-color-light-primary:#ffffff;
$background-color-light-secondary:#fafafa;
$background-color-light-tertiary:#f0f0f0;
$background-dark-submenu:#0b8ed8;
$background-light-submenu:#efefef;
$disable-button:#333;
$background-color-badges: #0B8ED8;
$background-color-progressbar-light: #E9E7E7;
$background-box-light: #C0BACA;
$progress-bar: #f2f2f280;
$background-progress-bar-perfil: #FFD394;
$separator-modules-page-class-video: #0B8ED8;
$list-inativo-light: #d7291226;
$list-inativo: #D72912;
$skeleton-color-light: #E1E1E1;
$skeleton-highlight-color: #0B8ED8;
$skeleton-highlight-color-light: #D0D0D0;
$title-color-devstars: #5C5491;
$button-color-aulas-painel: #81BEEA;
$color-default: #DCDCDC;
$tootle-admin: #3F51B5;
// Meus pedidos
$payment-color: #F2F2F2;
$disapproved-color: #EB5757;
$await-payment-color: #F2C94C;
// Dev Stars heatmap colors
$heatmap-color-primary: rgba(59, 212, 45, 1);
$heatmap-color-secondary: rgba(59, 212, 45, 0.75);
$heatmap-color-tertiary: rgba(59, 212, 45, 0.5);
$heatmap-color-quartiary: rgba(59, 212, 45, 0.25);

$training-button-is-continue: #ffffff;
:export {
  primaryColor1: $primary-color-1;
  primaryColor2: $primary-color-2;
  primaryColor3: $primary-color-3;
  secondaryColor1: $secondary-color-1;
  detailColorLight1: $detail-color-light-1;
  detailColorLight2: $detail-color-light-2;
  detailColorLight3: $detail-color-light-3;
  detailColorLight4: $detail-color-light-4;
  detailColorLight5: $detail-color-light-5;
  detailColorLight6: $detail-color-light-6;
  colorTextDark: $text-color-dark;
  colorTextLight: $text-color-light;
  colorSeparatorModulesPageClass: $separator-modules-page-class-video;
  dotSliderSecondary: $dot-slider-secondary;
  primaryColorMais1code: $primary-color-mais1code;
  secondaryColorMais1code: $secondary-color-mais1code;
  backgoundStatusTrasactionsPrimary:$backgound-status-trasactions-primary;
  backgoundStatusTrasactionsSecondary:$backgound-status-trasactions-secondary;
  backgoundStatusTrasactionsTertiary: $backgound-status-trasactions-tertiary;
  statusTrasactionsPrimary:$status-trasactions-primary;
  statusTrasactionsSecondary:$status-trasactions-secondary;
  footerColorPrimary: $footer-color-primary;
  footerColorSecondary: $footer-color-secondary;
  footerColorTertiary: $footer-color-tertiary;
  footerColorQuartiary: $footer-color-quartiary;
  footerColorQuintiary: $footer-color-quintiary;
  backgroundColorDarkPrimary: $background-color-dark-primary;
  backgroundColorDarkSecondary: $background-color-dark-secondary;
  backgroundColorDarkTertiary: $background-color-dark-tertiary;
  backgroundColorDarkQuartiary: $background-color-dark-quartiary;
  backgroundModalTelaAssistir: $background-modal-tela-assistir;
  backgroundTooltip: $background-tooltip;
  backgroundModalSuggestions: $background-modal-suggestions;
  backgroundColorLightPrimary: $background-color-light-primary;
  backgroundColorLightSecondary: $background-color-light-secondary;
  backgroundColorLightTertiary: $background-color-light-tertiary;
  submenuBackgroundDark: $background-dark-submenu;
  submenuBackgroundLight: $background-light-submenu;
  disableButton: $disable-button;
  colorDefault: $color-default;
  hrPrimary: $primary-color-2;
  hrSecondary: $footer-color-quartiary;
  backgroundColorBadges: $background-color-badges;
  backgroundColorProgressbarLight: $background-color-progressbar-light;
  backgroundBoxLight: $background-box-light;
  progressBar: $progress-bar;
  backgroundProgressBarPerfil: $background-progress-bar-perfil;
  listInativoLight: $list-inativo-light;
  listInativo: $list-inativo;
  skeletonColorLight: $skeleton-color-light;
  skeletonHighlightColor: $skeleton-highlight-color;
  skeletonHighlightColorLight: $skeleton-highlight-color-light;
  titleColorDevstars: $title-color-devstars;
  buttonColorAulasPainel: $button-color-aulas-painel;
  toogleAdmin: $tootle-admin;
  paymentColor: $payment-color;
  disapprovedColor: $disapproved-color;
  awaitPaymentColor: $await-payment-color;
  heatamapColorPrimary: $heatmap-color-primary;
  heatamapColorSecondary: $heatmap-color-secondary;
  heatamapColorTertiary: $heatmap-color-tertiary;
  heatamapColorQuartiary: $heatmap-color-quartiary;
  trainingButtonIsContinue: $training-button-is-continue;
}
